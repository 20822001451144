.about_section {
  height: 100vh;
  background-size: cover;
}

#about {
  transition: top 500ms;
}

.about_section_background_overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.75);
}

.full_page_white_gradient {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: white;
}

#about-section {
  margin-top: 130px;
  padding: 0 !important;
}

.black_circle {
  background-color: #191919;
  box-shadow: 0px 0px 5px #19191973;
  border-radius: 50%;
  z-index: 2000;
  bottom: 120px;
  left: -40px;
  width: 150px;
  height: 150px;
}

#mobile-logo-top {
  display: none;
}

#single-logo {
  top: 10vh;
  height: 100vh;
  right: -10vw;
  z-index: 1;
}

.img_container {
  overflow: visible;
  position: fixed;
  padding: 100px 0;
  width: 100vw;
}

/* MEDIA QUERY FOR LARGE DESKTOP */
@media only screen and (max-width:1270px){
  
  .black_circle {
    display: none;
  }

  #single-logo {
    right: -15vw;
  }
}

/* MEDIA QUERY FOR LARGE TABLETS AND SMALL DESKTOP */
@media only screen and (max-width:992px){

  #single-logo {
    height: 90vh;
    right: -15vw;
  }
}

@media only screen and (max-width:860px){

  #single-logo {
    height: 90vh;
    right: -30vw;
  }
}

/* MEDIA QUERY FOR TABLETS */
@media only screen and (max-width:768px){

  .img_container {
    position: unset;
  }
  
  .about_section {
    display: flex;
    background-size: cover;
  }

  .about_section_background_overlay {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  #single-logo {

    width: 50vw;
    margin: auto;
    order: 1;
    display: block;
    height: unset;
    position: relative !important;
    top: unset;
    right: unset;
  }

  #mobile-logo-top {
    display: block;
    position: absolute;
    top: 40px;
    left: 17px;
    width: 240px;
  }

  #about-section {
    margin-top: 0; 
    order: 2;
    z-index: 11;
  }

  #separation {
    display: none;
  }
 }

/* MEDIA QUERY FOR MOBILE */
@media only screen and (max-width:640px){

 }
